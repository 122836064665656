@font-face {
    font-family: "Circular Std Book";
    src: url('../fonts/CircularStd-Book.otf');
}
@font-face {
    font-family: "Circular Std Book";
    font-style: italic;
    src: url('../fonts/CircularStd-BookItalic.otf');
}
@font-face {
    font-family: "GROBOLD";
    src: url('../fonts/GROBOLD.ttf');
}

@font-face {
    font-family: "Bebas Neue";
    src: url('../fonts/BebasNeue.otf');
}
@font-face {
    font-family: "Circular Std Black";  
    src: url('../fonts/CircularStd-Black.otf');
}
@font-face {
    font-family: "Circular Std Black";
    font-style: italic;
    src: url('../fonts/CircularStd-BlackItalic.otf');
}
@font-face {
    font-family: "Roboto";
    font-weight: bold;
    src: url('../fonts/Roboto-Bold.ttf');
}

body{
    overflow-x: hidden;
}
.main{
    min-height: calc(100vh);
    margin-bottom: 20px;
}

h1{
    font-family: 'GROBOLD';
    font-size:35px;
    text-align:center;
    margin-bottom: 20px;
}

h2, h3, h4{
    font-family: 'Circular Std Book';
    font-weight: bold;
    text-align:center;
}

h2{
    font-size:20px;
}

h3{
    font-size:18px;
}

h4{ 
    font-size:16px;
}

p{  
    font-family: 'Circular Std Book';
    font-size:14px;
}

.bk_white{
    background-color:#FFF;
}

.bk_bluelight{
    background-color:#5ec8d6;
}
.bk_yellow{
    background-color:#f9b11f;
}
.bk_red{
    background-color:#f16651;
}

/***************/
nav {
    position: fixed;
    background-color: #00546e;
    top: 0;
    width: 100%;
    height: 64px;
    z-index: 100;
    padding-top:5px;
    padding-right:5px;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);

    box-shadow: 0px 5px 3px -3px black;
}


div.logo {
    position: absolute;
    background: url(../../images/logo.png) center center no-repeat;
    background-size: auto;
    background-size: cover;
    top: 10px;
    left: 30px;
    width: 100%;
    max-width: 125px;
    height: 100%;
    height: 155%;
    z-index: 100;
}


#content{
    margin-top:65px;
}

.btn_list{
    border-radius: 50%;
    padding: 5px;
}

.iconNav{
    border-radius: 50%;
    padding:5px;
    margin:5px;
    width:30px;
    height:30px;
    color:#00546e;
    cursor: pointer;
}

.badge-header{
    margin-top: 15px;
    margin-left: -15px;
    position: absolute;

}


/************/
#contentNotification{
    position: absolute; 
    right: 0;
    min-width: 300px;
    z-index:1000;
}

.carousel-item{
    height:42vh;
}

.carousel-item img{
    max-height: 100%;
    margin: auto;
}

.popover{
    max-width: 300px !important;
}


/***** FORMULAIRES *******/
.simple_form{
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    margin-top:20px;
    margin-bottom:20px;
    margin-left:auto;
    margin-right:auto;

    background-color: #00546e;
    color:white;
    padding:20px;
}
.simple_form fieldset {
 padding:0 20px 20px 20px;
 margin-bottom:10px;
 border:1px solid #5ec8d6;
}
.simple_form legend {
    font-family: 'Circular Std Book';
    color:#5ec8d6;
    font-weight:bold
}
.simple_form label{
    font-family: 'Circular Std Book';
    font-size:12pt;
    color:white;
}


.fc-button-primary{
    background-color: #00546e;
    border-color: #00546e;
}

.fc-header-toolbar h2{
    font-family: 'GROBOLD';
    font-size:20px;
    text-transform: uppercase;
    color: #00546e;
}

/**********/
.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  border-radius: 50%;
  background: #4CAF50; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  border-radius: 50%;
  background: #4CAF50; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

/* FOOTER */
footer{
    position:relative;
    width:100vw;
    overflow-y:hidden;
    background-image: url('../../images/backgrd-footer.jpg');
    background-repeat: repeat-x;
    height:340px;

    color:white;
}

footer a{
    color:#f16651;
}

footer #links{
    width:20%;
    float:left;
    margin-right: 10%;
    margin-left: 5%;
}

footer h2{
    text-align: left;
}

footer #links ul{
    padding:0;
}

footer #links li{
    list-style-type: none;
    padding:2px;
}

footer #about{
    width:30%;
    text-align:justify;
    float:left;
    margin-right: 10%;
}

footer #contact{
    width:20%;
    float:left;
    margin-right: 2%;
}
footer #soc_net{
    width:10%;
    float:left;
}
footer #copyright{
    width:100%;
    float:left;
    text-align:center;
}