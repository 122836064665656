#welcome_top{
    position: absolute;
    background: url(../../images/BanniereJeuxAccueil.jpg) center center;
    background-size: auto;
    background-size: cover;
    top:64px;
    width: 100%;
    height: 50vh;
}

#baseline{
	font-family: 'GROBOLD';
	height:65px;
	color:#FFF;
	font-size: 23pt;
	padding-top:20px;
	padding-right:20px;
	text-align:right;
}

#welcome_search{
	width:70vw;
	margin:auto;
}

#form_quicksearch{
	width:100%;
	background-color: rgba(0,0,0,0.5);
	height:18vh;
	/*padding-left:20%;*/
	text-align:center;
	border-radius: 20px;
	padding:10px;
	margin-top:20vh;
}

#form_quicksearch label{
	font-family: 'Bebas Neue';
	font-size:2em;
	color:white;
}

.search_btn{
	font-family: 'Circular Std Black';
	margin : 2px;
}

/********/
.card-welcome{
  padding-bottom: 10px;
  margin: 1vh 0 1vh 1vw;
  box-shadow: 2px 2px 2px grey;
}

.card-welcome legend{
     width: 60%;
}

.card-welcome .card-header .card-title{
	font-family: 'GROBOLD';
    font-size:20px;
}

.card-welcome .card-header{
    padding: .15rem 1.25rem .15rem 1.25rem;
    color: #00546e;
}

.card-welcome .card-body {
  	padding: 10;
  	text-align: justify;
  	font-size:16px !important;
}

.card-stats .card-footer hr {
  margin-top: 10px;
  margin-bottom: 15px;
}