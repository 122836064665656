#content_list_result{
	width:85vw;
	margin-left: auto;
	margin-right: auto;
}

#nb_result{
	margin-left:5%;
	margin-top:45px;
	font-family: 'Circular Std Black';
	font-size: 20px;
	font-style: italic;
}

.item_list_game_content{
	height:145px;
	margin-top:15px;
	border-radius: 25px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
}

.item_list_icon{
	position: absolute;
    margin-left: 1.1vw;
    margin-top: 2px;
    z-index: 100;
}
.item_list_icon img{
	width:40px;
	height:40px;
}
.item_list_elem{
	height:115px;
	margin:10px;
	border-radius: 25px;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	float:left;
}

.item_list_picture{
	text-align:center;
}

.item_list_picture img{
	height:115px;
	width:inherit;
	border:3px solid white;
	border-radius: 15px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	object-fit: cover;
}

.item_list_short_desc{
	background-color:white;
	width:22%;
	padding:10px 5px 0px 10px;
}

.item_list_long_desc{
	font-family: 'Circular Std Book';
	font-size: 13px;
	background-color:white;
	width:52%;
	padding:10px 5px 0px 10px;
}

.item_list_title, .item_list_lieu{
	font-family: 'Circular Std Black';
	font-size: 14px;
}

.item_list_type{
	font-family: 'Circular Std Bold';
	font-size: 14px;
	color: #f16651;
}


.item_list_orglabel{
	font-family: 'Circular Std Book Italic';
	font-size: 13px;
}

.item_list_orgname{
	font-family: 'Circular Std Black';
	font-size: 14px;
	color: #f16651;
}

.item_list_butinsc[itemprop="reg_button"]{
	color:white;
	background-color:#303030;
	border: 1px solid #303030;
}

.item_list_butinsc[itemprop="unreg_button"]{
	background-color:#008000;
	border: 1px solid #008000;
	color:white;
}

.item_list_butinsc[itemprop="waitreg_button"]{
	background-color:#D86D2B;
	border: 1px solid #D86D2B;
	color:white;
}

.item_list_butinsc{
	font-family: 'Circular Std Black';	
	width:100%;
	height:50px;
}

.item_list_nbplaymax{
	margin-left:-20px;
	background-image: url('../../images/rounded_rec_blue.png');
}

.item_list_nbplay{
	margin-left: 25%;
	background-image: url('../../images/rounded_rec_yellow.png');
}

.item_list_nbplay, .item_list_nbplaymax{
	margin-top:-20px;
	height:95px;
	width:25%;
	float:left;
	background-repeat: no-repeat;
	background-size: 100%;
	font-family: 'Circular Std Black';
	color:white;
	font-size: 1.8em;
	text-align:center;
	padding-top:10px;
}

.item_reg_zone{
	margin-left:auto;
	margin-right:auto;
}

/* Détails d'une partie */
#game_details_bloc{
	width:85vw;
	height:80vh;

	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	margin-top:20px;
	margin-bottom:20px;
	margin-left:auto;
	margin-right:auto;

	background-color: #00546e;
}


#game_details_main_zone{
	margin-top:20px;
	width:95%;
	margin-left:auto;
	margin-right:auto;
}

#game_details_main_zone_left{
	margin-top:15px;
	width:22%;
	height:420px;
	float:left;
}

.game_details_sub{
	margin-top:10px;
	width:100%;
	height:65px;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;

	color:black;
	text-align:center;
	font-size:19px;
	font-family: 'Circular Std Black';
	padding-top: 20px;

}

#game_details_main_zone_right{
	width:77%;
	height:435px;
	border-radius: 25px;
	float:left;
}

.game_details_element{
	width:97%;
	margin-top:15px;
	height:405px;
	margin-left:auto;
	margin-right:auto;
	background-color:#FFF;
}
